import Swiper from 'swiper/bundle'
export const carousel = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const basicSwipers = document.querySelectorAll('.m__carousel__basic')
    basicSwipers.forEach((basicSwiper) => {
      const swiperListCount = basicSwiper.querySelectorAll('.swiper-slide').length
      if (swiperListCount > 1) {
        new Swiper(basicSwiper, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }
    })
    if (document.querySelectorAll('.m__carousel__seminar .swiper-slide').length > 1) {
      new Swiper('.m__carousel__seminar', {
        loop: true,
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    }
    if (document.querySelectorAll('.m__carousel__introduce-member .swiper-slide').length > 1) {
      new Swiper('.m__carousel__introduce-member', {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          737: {
            spaceBetween: 30,
          },
        },
      })
    }
    if (document.querySelectorAll('.m__carousel__interview .swiper-slide').length > 1) {
      new Swiper('.m__carousel__interview', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 3500,
        },
      })
    }
    const recruitList = document.querySelectorAll('.m__carousel__recruit')
    const recruitListPrev = document.querySelectorAll(
      '.m__carousel__recruit-list .swiper-button-prev'
    )
    const recruitListNext = document.querySelectorAll(
      '.m__carousel__recruit-list .swiper-button-next'
    )
    if (recruitList.length > 0) {
      recruitList.forEach((recruitListBlock) => {
        let sliderCount = recruitListBlock.children[0].children.length
        let carouselStartCount = 3
        if (window.innerWidth < 738) {
          if (recruitListBlock.classList.contains('js__pc-swiper') == false) {
            carouselStartCount = 1
          } else {
            sliderCount = 0
          }
        }
        if (sliderCount > carouselStartCount) {
          new Swiper(recruitListBlock, {
            loop: true,
            slidesPerView: 'auto',
            loopAdditionalSlides: 2,
            centeredSlides: true,
            navigation: {
              nextEl: '.m__carousel__recruit-list .js-swiper-next',
              prevEl: '.m__carousel__recruit-list .js-swiper-prev',
            },
            breakpoints: {
              737: {
                centeredSlides: false,
              },
            },
          })
        }
      })
    }
    const modalPhotoSwiper = new Swiper('.m__carousel__photo-modal', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 30,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })
    new Swiper('.m__carousel__photo-album', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: modalPhotoSwiper,
      },
    })
    const modalPhotoWrap = document.querySelectorAll(
        '.m__recruit__photo-modal-element'
      ),
      modalOpenBtns = document.querySelectorAll(
        '.m__carousel__photo-album .swiper-slide'
      ),
      modalPhotoEle = document.querySelectorAll(
        '.m__recruit__photo-modal-element .swiper-slide'
      ),
      modalNext = document.querySelectorAll(
        '.m__recruit__photo-modal-element .swiper-button-next'
      ),
      modalPrev = document.querySelectorAll(
        '.m__recruit__photo-modal-element .swiper-button-prev'
      )
    if (modalPhotoWrap[0] != null) {
      modalOpenBtns.forEach((modalOpenBtn) => {
        modalOpenBtn.addEventListener('click', () => {
          modalPhotoWrap[0].classList.add('is-open')
        })
      })
      modalPhotoWrap[0].addEventListener('click', () => {
        modalPhotoWrap[0].classList.remove('is-open')
      })
      modalPhotoEle.forEach((modalPhotoEle) => {
        modalPhotoEle.addEventListener('click', (e) => {
          e.stopPropagation()
        })
      })
      modalNext[0].addEventListener('click', (e) => {
        e.stopPropagation()
      })
      modalNext[0].addEventListener('click', (e) => {
        e.stopPropagation()
      })
      modalPrev[0].addEventListener('click', (e) => {
        e.stopPropagation()
      })
    }
    new Swiper('.m__carousel__staff-interview', {
      loop: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        737: {
          loop: false,
          centeredSlides: false,
        },
      },
    })
    new Swiper('.m__carousel__staff-interview02', {
      loop: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      autoplay: {
        delay: 5000,
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        737: {
          centeredSlides: false,
        },
      },
    })
    if (window.innerWidth < 737) {
      new Swiper('.m__cat__staff-interview', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      })
    }

    if (window.innerWidth < 737) {
      new Swiper('.m__carousel__faq', {
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    }
    const facilitySwipers = document.querySelectorAll('.m__carousel__facility')
    facilitySwipers.forEach((facilitySwiper) => {
      const swiperListCount = facilitySwiper.querySelectorAll('.swiper-slide').length
      if (swiperListCount > 1) {
        new Swiper(facilitySwiper, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          autoplay: {
            delay: 5000,
          },
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }
    })
    new Swiper('.m__carousel__top-mv', {
      loop: true,
      effect: 'fade',
      speed: 1500,
      autoplay: {
        delay: 3500,
      },
    })
    new Swiper('.m__carousel__top__saiyo-mv', {
      loop: true,
      effect: 'fade',
      speed: 1500,
      autoplay: {
        delay: 3500,
      },
    })
  })
  window.addEventListener('load', () => {
    const bannerSwipers = document.querySelectorAll('.m__carousel__banner')
    bannerSwipers.forEach((bannerSwiper) => {
      const swiperLength = bannerSwiper.querySelectorAll('.swiper-slide').length
      let sliderCount = 3
      if (window.innerWidth < 737) {
        sliderCount = 1
      }
      if (sliderCount < swiperLength) {
        new Swiper(bannerSwiper, {
          loop: true,
          slidesPerView: 'auto',
          centeredSlides: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
        })
      }
    })
  })
}
