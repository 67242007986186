export const scrollFixed = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const fixedElem = document.querySelector('.js__scroll-fixed')
    const bottomFloatElem = document.querySelector('.js__float-block')
    const bottomFixedElem = document.querySelector('.js__fixed-block')
    const footer = document.querySelector('.m__footer')
    const operateFloatElem = document.querySelector('.js__float-scroll-operate')
    let bottomPos = 100
    let bottomFloatElemHeight = 0
    if (bottomFloatElem != null) {
      bottomFloatElemHeight = bottomFloatElem.clientHeight
      fixedElem.style.bottom = bottomFloatElemHeight + 20 + 'px'
    }
    if (bottomFixedElem != null) {
      bottomFloatElemHeight = bottomFixedElem.clientHeight
      fixedElem.style.bottom = bottomFloatElemHeight + 20 + 'px'
      footer.style.paddingBottom = bottomFloatElemHeight + 'px'
    }
    window.addEventListener('scroll', () => {
      if (fixedElem != null) {
        const docHeight = document.body.clientHeight,
          scrollCount = document.documentElement.scrollTop,
          scrollPos = document.documentElement.clientHeight + scrollCount,
          footerHeight = document.querySelector('footer').clientHeight,
          breadcrumbsSp = document.querySelector('.m__breadcrumb.sp'),
          bottomInformations = document.querySelectorAll('.m__common__bottom-information')
        let bottomInformationHeight = 0
        if (bottomInformations != null) {
          bottomInformations.forEach((bottomInformation) => {
            if (!bottomInformation.classList.contains('m__common__bottom-information--v2')) {
              bottomInformationHeight = bottomInformation.clientHeight
            }
          })
        }
        let breadcrumbsHeight = 0
        if (window.innerWidth < 768) {
          bottomPos = 255
          if (breadcrumbsSp != null) {
            breadcrumbsHeight = breadcrumbsSp.clientHeight
            bottomPos = breadcrumbsHeight + 35
          }
          if (bottomFloatElem != null || bottomFixedElem != null) {
            breadcrumbsHeight = breadcrumbsSp.clientHeight
            bottomPos = breadcrumbsHeight + bottomFloatElemHeight + 15
          }
        } else {
          if (bottomFloatElem != null || bottomFixedElem != null) {
            breadcrumbsHeight = breadcrumbsSp.clientHeight
            bottomPos = breadcrumbsHeight + bottomFloatElemHeight + 50
          }
        }
        if (
          docHeight - scrollPos >=
          footerHeight + bottomInformationHeight + breadcrumbsHeight - bottomPos
        ) {
          fixedElem.classList.remove('fixed')
        } else {
          fixedElem.classList.add('fixed')
        }
      }
    })
    if (operateFloatElem != null) {
      const floatStart = document.getElementById('js__float-start-point')
      const floatEnd = document.getElementById('js__float-end-point')
      const floatHeader = document.querySelector('.js__header-fixed')
      if (floatStart != null && floatEnd != null) {
        const floatStartPoint =
          floatStart.getBoundingClientRect().top + window.pageYOffset
        const floatEndPoint =
          floatEnd.getBoundingClientRect().top +
          window.pageYOffset -
          window.innerHeight
        const operateFloatElemHeight = operateFloatElem.clientHeight
        operateFloatElem.style.bottom = -operateFloatElemHeight + 'px'
        window.addEventListener('scroll', () => {
          const scrollCount = document.documentElement.scrollTop
          if (scrollCount > floatStartPoint && floatEndPoint > scrollCount) {
            operateFloatElem.style.bottom = 0
            fixedElem.style.bottom = operateFloatElemHeight + 20 + 'px'
            if (floatHeader != null) {
              if (window.innerWidth < 768) {
                floatHeader.style.top = '-63px'
              } else {
                floatHeader.style.top = '-128px'
              }
            }
          } else {
            operateFloatElem.style.bottom = -operateFloatElemHeight + 'px'
            if (window.innerWidth < 768) {
              fixedElem.style.bottom = '40px'
            } else {
              fixedElem.style.bottom = '70px'
            }
            if (floatHeader != null) {
              floatHeader.style.top = 0
            }
          }
        })
      }
    }
  })
}
