export var syncCheckedStatus = function () {
    var formEl = document.getElementById('js-job-search-form');
    if (!formEl) {
        return;
    }
    var checkBoxes = Array.from(formEl.querySelectorAll('input[type="checkbox"]'));
    checkBoxes.forEach(function (el) {
        el.addEventListener('change', function (e) {
            if (!(e.currentTarget instanceof HTMLInputElement)) {
                return;
            }
            var checked = e.currentTarget.checked;
            var name = e.currentTarget.name;
            var value = e.currentTarget.value;
            formEl
                .querySelectorAll("input[name=\"".concat(name, "\"][value=\"").concat(value, "\"]"))
                .forEach(function (checkBoxEl) {
                if (!(checkBoxEl instanceof HTMLInputElement)) {
                    return;
                }
                if (checkBoxEl.checked != checked) {
                    checkBoxEl.checked = checked;
                }
            });
        });
    });
};
