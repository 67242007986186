export const bracketsCenterText = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const bracketsElem = document.querySelectorAll('.m__common__text-brackets')
    bracketsElem.forEach((brackets) => {
      const bracketsHeight = brackets.clientHeight
      let heightLine1 = 0
      if (window.innerWidth > 767) {
        heightLine1 = 100
      } else {
        heightLine1 = 65
      }
      if (bracketsHeight < heightLine1) {
        if (window.innerWidth > 767) {
        brackets.style.padding = '40px 40px 30px'
      } else {
        brackets.style.padding = '20px 20px 10px'
      }
      }
    })
  })
}
