export const smoothScroll = () => {
  const Ease = {
    easeInOut: function (t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
  }
  const duration = 500
  window.addEventListener('DOMContentLoaded', function () {
    const smoothScrollTriggers = [].slice.call(
      document.querySelectorAll('a[href^="#"]')
    )
    const header = document.querySelector('.m__header')
    const hasClass = header.classList.contains('js__header-fixed')
    smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
      smoothScrollTrigger.addEventListener('click', function (e) {
        const href = smoothScrollTrigger.getAttribute('href')
        const currentPostion =
          document.documentElement.scrollTop || document.body.scrollTop
        const targetElement = document.getElementById(href.replace('#', ''))
        if (targetElement) {
          e.preventDefault()
          e.stopPropagation()
          let targetPosition =
            window.pageYOffset + targetElement.getBoundingClientRect().top
          // 固定header分ずらす
          if (hasClass) {
            if (window.innerWidth > 767) {
              targetPosition = targetPosition - 110
            } else {
              targetPosition = targetPosition - 54
            }
          }
          const startTime = performance.now()
          const loop = function (nowTime) {
            const time = nowTime - startTime
            const normalizedTime = time / duration
            if (normalizedTime < 1) {
              window.scrollTo(
                0,
                currentPostion +
                  (targetPosition - currentPostion) *
                    Ease.easeInOut(normalizedTime)
              )
              requestAnimationFrame(loop)
            } else {
              window.scrollTo(0, targetPosition)
            }
          }
          requestAnimationFrame(loop)
        }
      })
    })
    window.addEventListener('load', function () {
      if (window.location.hash == '') {
        return
      }
      setTimeout(function () {
        const hash = document.getElementById(window.location.hash.slice(1))
        hash.scrollIntoView(true)
        if (hasClass) {
          if (window.innerWidth > 767) {
            window.scrollTo(0, window.pageYOffset - 150)
          } else {
            window.scrollTo(0, window.pageYOffset - 54)
          }
        }
      }, 0)
    })
  })
}
