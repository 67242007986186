export const accordion = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const slideUp = (el, duration = 300) => {
      el.style.height = el.offsetHeight + 'px'
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      setTimeout(() => {
        el.style.display = 'none'
        el.style.removeProperty('height')
        el.style.removeProperty('padding-top')
        el.style.removeProperty('padding-bottom')
        el.style.removeProperty('margin-top')
        el.style.removeProperty('margin-bottom')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    }

    // slideDown
    const slideDown = (el, duration = 300) => {
      el.style.removeProperty('display')
      let display = window.getComputedStyle(el).display
      if (display === 'none') {
        display = 'block'
      }
      el.style.display = display
      let height = el.offsetHeight
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.height = height + 'px'
      el.style.removeProperty('padding-top')
      el.style.removeProperty('padding-bottom')
      el.style.removeProperty('margin-top')
      el.style.removeProperty('margin-bottom')
      setTimeout(() => {
        el.style.removeProperty('height')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    }

    const slideToggle = (el, duration = 300) => {
      if (window.getComputedStyle(el).display === 'none') {
        return slideDown(el, duration)
      } else {
        return slideUp(el, duration)
      }
    }

    const trigger = document.querySelectorAll('.js__accordion-trigger')
    function toggle() {
      const content = this.previousElementSibling
      this.classList.toggle('open')
      slideToggle(content, 300)
    }
    for (let i = 0; i < trigger.length; i++) {
      trigger[i].addEventListener('click', toggle)
    }
    // 介護の求人を探す 都道府県のチェックボックスを選択すると市区町村を選択するグレーの要素を表示させる
    const checkBoxSlideTriggers = document.querySelectorAll(
      '.checkbox-area-trigger'
    )
    let beforeCount = 0
    checkBoxSlideTriggers.forEach((checkBoxSlideTrigger) => {
      const targetCheckBoxs = checkBoxSlideTrigger.querySelectorAll(
        '.m__form__checkbox input'
      )
      targetCheckBoxs.forEach((targetCheckBox) => {
        let checkCount = 0
        for (let i = 0; targetCheckBoxs.length > i; i += 1) {
          if (targetCheckBoxs[i].checked == true) {
            checkCount += 1
          }
        }
        if (checkCount > 0) {
          checkBoxSlideTrigger.nextElementSibling.style.display = 'block'
        }
        beforeCount = checkCount
        targetCheckBox.addEventListener('change', () => {
          const checkValue = targetCheckBox.getAttribute('value')
          const cityIdElements = document.querySelectorAll('[data-city-prefecture-id]')
          cityIdElements.forEach((cityIdElement) => {
            const prefectureId = cityIdElement.getAttribute('data-city-prefecture-id')
            if (prefectureId == checkValue) {
              if (targetCheckBox.checked == true) {
                cityIdElement.style.display = 'block'
              } else {
                cityIdElement.style.display = 'none'
              }
            }
          })

          checkCount = 0
          for (let i = 0; targetCheckBoxs.length > i; i += 1) {
            if (targetCheckBoxs[i].checked == true) {
              checkCount += 1
            }
          }
          if (checkCount > beforeCount) {
            if (checkCount == 1 && beforeCount < checkCount) {
              slideDown(checkBoxSlideTrigger.nextElementSibling, 500)
            }
          }
          if (checkCount == 0) {
            slideUp(checkBoxSlideTrigger.nextElementSibling, 500)
          }
          beforeCount = checkCount
        })
      })
    })
  })
}
