var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var refreshCount = function () {
    document.addEventListener('DOMContentLoaded', function () {
        addFormEventListener(document.getElementById('js-job-search-form-pc'));
        addFormEventListener(document.getElementById('js-job-search-form-sp'));
    });
};
var addFormEventListener = function (formEl) { return __awaiter(void 0, void 0, void 0, function () {
    var checkBoxes, queryString;
    return __generator(this, function (_a) {
        if (!formEl) {
            return [2 /*return*/];
        }
        checkBoxes = Array.from(formEl.querySelectorAll('input[type="checkbox"]'));
        queryString = function () {
            return checkedElements(checkBoxes)
                .map(function (el) {
                return "".concat(el.name, "=").concat(el.value);
            })
                .join('&');
        };
        checkBoxes.forEach(function (el) {
            el.addEventListener('change', function (e) { return __awaiter(void 0, void 0, void 0, function () {
                var hitCount;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(e.currentTarget instanceof HTMLInputElement)) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, fetchCount("".concat(formEl.dataset.countPath, "?").concat(queryString()))];
                        case 1:
                            hitCount = _a.sent();
                            formEl
                                .querySelectorAll(formEl.dataset.countSelector)
                                .forEach(function (textEl) {
                                textEl.innerHTML = hitCount.toString();
                            });
                            document
                                .querySelectorAll('[data-search-submit-button="1"]')
                                .forEach(function (buttonEl) {
                                if (buttonEl instanceof HTMLButtonElement ||
                                    buttonEl instanceof HTMLInputElement) {
                                    buttonEl.disabled = parseInt(hitCount.toString(), 10) == 0;
                                    if (buttonEl.disabled) {
                                        buttonEl.closest('div').classList.remove('m__btn__blue');
                                        buttonEl.closest('div').classList.add('m__btn__gray');
                                    }
                                    else {
                                        buttonEl.closest('div').classList.add('m__btn__blue');
                                        buttonEl.closest('div').classList.remove('m__btn__gray');
                                    }
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            }); });
        });
        return [2 /*return*/];
    });
}); };
var checkedElements = function (els) {
    return els.filter(function (el) {
        return el instanceof HTMLInputElement && el.checked;
    });
};
var fetchCount = function (path) { return __awaiter(void 0, void 0, void 0, function () {
    var result, json, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                return [4 /*yield*/, fetch(path, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })];
            case 1:
                result = _a.sent();
                return [4 /*yield*/, result.json()];
            case 2:
                json = _a.sent();
                return [2 /*return*/, json.count];
            case 3:
                e_1 = _a.sent();
                alert('件数を取得できませんでした');
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
