//社宅制度タブ切り替え
export const tabList = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const tabItem = document.querySelectorAll('.m__contentsTabList li')
    const tabContent = document.querySelectorAll('.tabWrapper .tabInner')

    for (let i = 0; i < tabItem.length; i++) {
      tabItem[i].addEventListener('click', tabToggle)
    }

    function tabToggle() {
      for (let i = 0; i < tabItem.length; i++) {
        tabItem[i].classList.remove('current')
      }
      for (let i = 0; i < tabContent.length; i++) {
        tabContent[i].classList.remove('current')
      }
      this.classList.add('current')
      const aryTabs = Array.prototype.slice.call(tabItem)
      const index = aryTabs.indexOf(this)
      tabContent[index].classList.add('current')
    }
    const tabSwitch = (tabs, contents) => {
      tabs.forEach((element) => {
        element.addEventListener('click', () => {
          tabs.forEach((element) => {
            element.classList.remove('current')
          })
          element.classList.add('current')
          contents.forEach((content) => {
            content.style.display = 'none'
          })
          const topTabItemData = element.dataset.tab
          const targetBlock = document.getElementById(topTabItemData)
          targetBlock.style.display = 'block'
        })
      })
    }
    const topTabItems = document.querySelectorAll(
      '.top-switch-tab .tab'
    )
    const topTabContents = document.querySelectorAll(
      '.top-switch-tab .block'
    )
    const modalTopTabItems = document.querySelectorAll(
      '.modal-switch-tab .tab'
    )
    const modalTabContents = document.querySelectorAll(
      '.modal-switch-tab .block'
    )
    const timeScheduleTabs = document.querySelectorAll('.m__cat__time-schedule .sp-tab-list .tab')
    const timeScheduleContents = document.querySelectorAll('.m__cat__time-schedule .schedule')
    tabSwitch(topTabItems, topTabContents)
    tabSwitch(modalTopTabItems, modalTabContents)
    tabSwitch(timeScheduleTabs, timeScheduleContents)
  })
}
