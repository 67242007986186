export const readmore = () => {
  document.addEventListener('DOMContentLoaded', () => {
    if (window.innerWidth < 737) {
      const readMoreBtns = document.querySelectorAll('.m__btn__read-more')
      if (readMoreBtns[0] != null) {
        readMoreBtns.forEach((readMoreBtn) => {
          const linkList = readMoreBtn.previousElementSibling.children
          const linkListWrap = readMoreBtn.previousElementSibling
          if (linkList.length > 5) {
            let linkHeight = 0
            let linkTotalHeight = 0
            for (let i = 0; i < 5; i += 1) {
              linkHeight = linkList[i].clientHeight + 10
              linkTotalHeight = linkTotalHeight + linkHeight
            }
            linkListWrap.style.height = linkTotalHeight + 'px'
          } else {
            readMoreBtn.style.display = 'none'
          }
          readMoreBtn.addEventListener('click', () => {
            linkListWrap.classList.add('is-open')
          })
        })
      }
    }
  })
}
