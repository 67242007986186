export const checkboxFloatBanner = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const cardInputButtons = document.querySelectorAll(
      '.m__search__list-card01 .card-header .ico'
    )
    const header = document.querySelector('.m__header')
    const floatResearch = document.querySelector('.float-research')
    if (cardInputButtons[0] != null) {
      const searchFixedBtn = document.querySelectorAll('.m__search__fixed')
      const pageTopBtn = document.querySelector('.js__scroll-fixed')
      const countCheck = () => {
        let inputCheckCount = 0
        cardInputButtons.forEach((cardInputButton) => {
          const targetInput = cardInputButton.previousElementSibling
          if (targetInput.checked) {
            if (targetInput.checked) {
              inputCheckCount += 1
            } else {
              inputCheckCount -= 1
            }
          }
        })
        if (inputCheckCount > 0) {
          searchFixedBtn[0].classList.add('floating')
          pageTopBtn.classList.add('move-top')
        } else {
          searchFixedBtn[0].classList.remove('floating')
          pageTopBtn.classList.remove('move-top')
        }
      }
      cardInputButtons.forEach((cardInputButton) => {
        cardInputButton.addEventListener('click', () => {
          setTimeout(function () {
            countCheck()
          }, 1)
        })
      })
      window.addEventListener('scroll', () => {
        const scrollCount = document.documentElement.scrollTop
        const scrollPos = document.documentElement.clientHeight + scrollCount
        const searchBlock = document.querySelector('.m__search__list-contents')
        const searchFirstCard = document.querySelector(
          '.m__search__list-contents .m__search__list-card01:first-child'
        )
        const searchBlockRect = searchBlock.getBoundingClientRect()
        const searchFirstCardRect = searchFirstCard.getBoundingClientRect()
        const searchBlockPos = searchBlockRect.top + window.pageYOffset
        const searchFirstCardPos =
          searchFirstCardRect.top +
          window.pageYOffset +
          searchFirstCard.clientHeight +
          window.innerHeight -
          header.clientHeight
        const searchBlockPosEnd =
          searchBlockPos + searchBlock.clientHeight + window.innerHeight
        if (scrollPos > searchBlockPos && searchBlockPosEnd > scrollPos) {
          countCheck()
        } else {
          searchFixedBtn[0].classList.remove('floating')
          pageTopBtn.classList.remove('move-top')
        }
        if (floatResearch != null) {
          const searchCardList = document.querySelector(
            '.m__search__card-list-wrap'
          )
          const searchCardListPos =
            searchCardList.getBoundingClientRect().top +
            window.pageYOffset +
            searchCardList.clientHeight

          if (scrollPos > searchFirstCardPos && searchCardListPos > scrollPos) {
            header.classList.add('hidden')
            floatResearch.classList.add('show')
            pageTopBtn.classList.add('move-top')
          } else {
            header.classList.remove('hidden')
            floatResearch.classList.remove('show')
            pageTopBtn.classList.remove('move-top')
          }
        }
      })
    }
  })
}
