export const displaySelecting = () => {
  // 選択中の表示判定（SP画面での検索条件を変更する）
  const checkboxCategorys = document.querySelectorAll('.modal-title')
  const selectElement = '<span class="checked">選択中</span>'
  const categoryCheck = (array, element) => {
    let checkCount = 0
    const checkElem = element.querySelectorAll('.checked')
    for (let j = 0; array.length > j; j += 1) {
      if (array[j].checked == true) {
        checkCount += 1
      }
    }
    if (checkCount > 0) {
      if (checkElem.length == 0) {
        element.insertAdjacentHTML('beforeend', selectElement)
      }
    } else {
      if (checkElem.length > 0) {
        for (let k = 0; checkElem.length > k; k += 1) {
          checkElem[k].remove()
        }
      }
    }
  }
  checkboxCategorys.forEach((checkboxCategory) => {
    const checkboxArray =
      checkboxCategory.nextElementSibling.querySelectorAll('input')
    categoryCheck(checkboxArray, checkboxCategory)
    checkboxArray.forEach((checkboxElement) => {
      checkboxElement.addEventListener('change', () => {
        categoryCheck(checkboxArray, checkboxCategory)
        const modalCountBlock = document.querySelector(
          '.m__modal__wrap .m__search__count-block'
        )
        const searchContents = document.querySelector(
          '.m__search__research-contents-block'
        )
        if (modalCountBlock != null) {
          const modalCountBlockHeight = modalCountBlock.clientHeight
          searchContents.style.paddingBottom = modalCountBlockHeight + 'px'
        }
      })
    })
  })
  // 選択中の表示設定（PCトップページ）
  const topModalSearchTabs = document.querySelectorAll(
    '.m__top__modal-switch-tab .tab'
  )
  topModalSearchTabs.forEach((topModalSearchTab) => {
    const targetBlock = document.getElementById(topModalSearchTab.dataset.tab)
    const topModalSearchCheckboxs = targetBlock.querySelectorAll('input')
    categoryCheck(topModalSearchCheckboxs, topModalSearchTab)
    topModalSearchCheckboxs.forEach((topModalSearchCheckbox) => {
      topModalSearchCheckbox.addEventListener('change', () => {
        categoryCheck(topModalSearchCheckboxs, topModalSearchTab)
      })
    })
  })

  // PCにおいて選択中のチェックボックスが存在した場合、「選択中」の表示をモーダル切り替えの要素に追加する
  const pcTopSearchModals = document.querySelectorAll(
    '.m__top__pc-modal-search'
  )
  // data-modal属性の値を取得（後に特定要素のIDの値と参照させるため）
  const dataModallClassElements = document.querySelectorAll('[data-modal]')
  pcTopSearchModals.forEach((pcTopSearchModal) => {
    // 開いているモーダルのinput要素全取得
    const pcTopModalSearchCheckboxs = pcTopSearchModal.querySelectorAll('input')
    pcTopModalSearchCheckboxs.forEach((pcTopModalSearchCheckbox) => {
      pcTopModalSearchCheckbox.addEventListener('change', () => {
        // 開いているモーダルのIDを取得
        const modalId = pcTopSearchModal.id
        // 開いているモーダル内で選択されたチェックボックスの数を取得
        let checkCount = 0
        for (let i = 0; pcTopModalSearchCheckboxs.length > i; i += 1) {
          if (pcTopModalSearchCheckboxs[i].checked == true) {
            checkCount += 1
          }
        }
        // ページ内の全てのdata-modal属性に対して、チェックボックスの状態に応じた処理を行う
        dataModallClassElements.forEach((dataModallClassElement) => {
          // 「選択中」の要素
          const checkElem = dataModallClassElement.querySelectorAll('.checked')
          const targetDataElement = dataModallClassElement.dataset.modal
          // 開いているモーダルのIDと、data-modalの値が一致している場合、「選択中」表示切り替え処理の対象とする
          if (targetDataElement == modalId) {
            // 開いているモーダルでチェックされた数が1つ以上であれば「選択中」の付与、なければ「選択中」の削除
            if (checkCount > 0) {
              if (checkElem.length == 0) {
                dataModallClassElement.insertAdjacentHTML(
                  'beforeend',
                  selectElement
                )
              }
            } else {
              if (checkElem.length > 0) {
                dataModallClassElement.querySelector('.checked').remove()
              }
            }
          }
        })
      })
    })
  })
}
