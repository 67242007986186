export const displayHitCount = () => {
  const searchCheckCountFloat = document.querySelector('.js__checkcount-float')
  const searchContentsCheckBoxs = document.querySelectorAll(
    '.js__checkbox-count input[type="checkbox"]'
  )
  const searchContentsBox = document.querySelector('.js__checkbox-count')
  const searchCheckResetBtns = document.querySelectorAll('.js__checkbox-reset')
  const pageTopBtn = document.querySelector('.js__scroll-fixed')
  const searchBlock = document.querySelector('.m__search__research-block')
  const checkcountFloat = document.querySelector('.js__checkcount-float')
  let scrollPos
  const movePageTopBtn = () => {
    if (searchBlock != null) {
      const searchBlockRect = searchBlock.getBoundingClientRect().top + window.pageYOffset + checkcountFloat.clientHeight
      const searchBlockEndPoint = searchBlockRect + searchBlock.clientHeight - checkcountFloat.clientHeight
      scrollPos = document.documentElement.clientHeight + document.documentElement.scrollTop
      if (checkcountFloat.classList.contains('float') == true) {
        if (scrollPos > searchBlockRect && searchBlockEndPoint > scrollPos) {
          pageTopBtn.style.bottom = '120px'
        } else {
          pageTopBtn.style.bottom = '40px'
        }
      }
    }
  }
  if (searchCheckCountFloat != null) {
    let searchCheckCountFloatHeight = searchCheckCountFloat.clientHeight
    if (document.querySelector('.m__search__not-modal-block') != null) {
      setTimeout(() => {
        searchCheckCountFloat.style.opacity = '1'
      }, 500)
      searchCheckCountFloat.style.bottom = -searchCheckCountFloatHeight + 'px'
    }
    searchContentsCheckBoxs.forEach((searchContentsCheckBox) => {
      searchContentsCheckBox.addEventListener('change', () => {
        searchCheckCountFloatHeight = searchCheckCountFloat.clientHeight
        let checkCount = 0
        for (let j = 0; searchContentsCheckBoxs.length > j; j += 1) {
          if (searchContentsCheckBoxs[j].checked == true) {
            checkCount += 1
          }
        }
        if (checkCount > 0) {
          searchCheckCountFloat.style.bottom = '0px'
          searchContentsBox.childNodes[0].style.paddingBottom =
            searchCheckCountFloatHeight + 'px'
          searchCheckCountFloat.classList.add('float')
        } else {
          searchCheckCountFloat.style.bottom =
            -searchCheckCountFloatHeight + 'px'
          searchContentsBox.childNodes[0].style.paddingBottom = '0px'
          searchCheckCountFloat.classList.remove('float')
        }
        movePageTopBtn()
      })
    })
    searchCheckResetBtns.forEach((searchCheckResetBtn) => {
      searchCheckResetBtn.addEventListener('click', () => {
        searchContentsCheckBoxs.forEach((searchContentsCheckBox) => {
          searchContentsCheckBox.checked == false
          setTimeout(() => {
            searchCheckCountFloat.style.bottom =
              -searchCheckCountFloatHeight + 'px'
          }, 300)
        })
      })
    })
  }
  window.addEventListener('scroll', () => {
    movePageTopBtn()
  })
}
