import { refreshCount } from './refresh-count'

export const clearCondition = () => {
  // 検索条件をクリア
  const checkAllResetBtns = document.querySelectorAll('.js__checkbox-reset')
  checkAllResetBtns.forEach((checkAllResetBtn) => {
    checkAllResetBtn.addEventListener('click', async () => {
      const allCheckboxs = document.querySelectorAll('.m__form__checkbox input')
      const modalTitleCheckBlocks = document.querySelectorAll(
        '.modal-title .checked'
      )
      // checkboxのchecked属性を外す
      allCheckboxs.forEach((allCheckbox) => {
        allCheckbox.checked = false
      })
      // 「選択中」の要素を削除する
      modalTitleCheckBlocks.forEach((modalTitleCheckBlock) => {
        modalTitleCheckBlock.remove()
      })
      // checkboxの change イベントを発火させてチェックボックスとボタンと件数の状態をリセットする
      if (allCheckboxs.length > 0) {
        allCheckboxs[0].dispatchEvent(new Event('change'))
      }
    })
  })
}
