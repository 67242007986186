export const spHeaderMenu = (str) => {
  document.addEventListener('DOMContentLoaded', () => {
    const menu = document.querySelectorAll(str)
    const header = document.querySelector('.m__header')
    const spMenu = document.querySelector('.js__sp-menu-target')
    let windowHeight = window.innerHeight - 60
    spMenu.style.cssText = 'height:' + windowHeight + 'px;'
    function toggle() {
      spMenu.scrollTop = 0
      this.classList.toggle('is-active')
      spMenu.classList.toggle('is-open')
      if (window.innerWidth < 768) {
        if (spMenu.classList.contains('is-open') == true) {
          document.body.style.cssText = 'overflow: hidden; height: 100%;'
          header.classList.add('open')
        } else {
          document.body.style.cssText = 'overflow: visible; height: auto;'
          header.classList.remove('open')
          menu[0].classList.remove('is-active')
        }
      }
    }
    for (let i = 0; i < menu.length; i++) {
      menu[i].addEventListener('click', toggle)
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        windowHeight = window.innerHeight - 60
        spMenu.style.cssText = 'height:' + windowHeight + 'px;'
      }
    })
    const spLink = document.querySelectorAll('.m__header a')
    for (let j = 0; j < spLink.length; j++) {
      spLink[j].addEventListener('click', (e) => {
        const target = e.currentTarget
        const urlHref = target.hash
        if (urlHref) {
          spMenu.classList.remove('is-open')
          menu[0].classList.remove('is-active')
          document.body.style.cssText = 'overflow: visible; height: auto;'
          header.classList.remove('open')
          setTimeout(function () {
            if (window.innerWidth > 767) {
              window.scrollTo(0, window.pageYOffset - 110)
            } else {
              window.scrollTo(0, window.pageYOffset - 60)
            }
          }, 0)
        }
      })
    }
  })
}
