export const mvScroll = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const mvBlock = document.querySelector('.m__top__saiyo-mv')
    const mvTextBlock = document.querySelector('.m__top__scroll-top')
    if (mvTextBlock != null) {
      const windowHeight = window.outerHeight
      const mvBlockPos =
        window.pageYOffset +
        mvTextBlock.getBoundingClientRect().top -
        windowHeight
      const topTextFadePoint = mvBlockPos + (window.innerHeight / 2)
      document.addEventListener('scroll', () => {
        const scroll = window.pageYOffset
        if (mvBlockPos < scroll) {
          mvBlock.classList.add('fade')
        } else {
          mvBlock.classList.remove('fade')
        }
        if (topTextFadePoint < scroll) {
          mvTextBlock.classList.add('fade')
        } else {
          mvTextBlock.classList.remove('fade')
        }
      })
    }
  })
}
