
// 全件チェック操作の対象になっている側のチェックボックスが操作された場合の、全件チェック用チェックボックスの操作
export const allCheckTarget = () => {
  const checkBoxChilds = document.querySelectorAll('.js__all-check-target')
  const topModal = document.querySelector('.m__top__pc-modal-search')
  checkBoxChilds.forEach((checkBoxChild) => {
    const childCheckboxs = checkBoxChild.querySelectorAll('input')
    const targetAllCheck = checkBoxChild.previousElementSibling.querySelector('input')
    const checkedCount = (status) => {
      let checkBoxChildLength = childCheckboxs.length
      let count = 0
      for (let i = 0; checkBoxChildLength > i; i += 1) {
        if (childCheckboxs[i].checked == true) {
          count += 1
        }
      }
      // 全件チェック操作の対象になっている側のチェックボックスがすべてチェックされた場合、全件チェック機能を持つチェックボックスのチェックもつける
      if (checkBoxChildLength == count) {
        targetAllCheck.checked = true
      // 1つでもチェックされていない場合は、全件チェック機能を持つチェックボックスのチェックを外す
      } else {
        if (status == 'change') {
          targetAllCheck.checked = false
        }
      }
    }
    checkedCount()
    childCheckboxs.forEach((childCheckbox) => {
      childCheckbox.addEventListener('change', () => {
        checkedCount('change')
      })
    })
  })
}
