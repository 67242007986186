import { allCheck } from './search-check-operate/all-check'
import { allCheckTarget } from './search-check-operate/all-check-target'
import { displaySelecting } from './search-check-operate/display-selecting'
import { clearCondition } from './search-check-operate/clear-condition'
import { displayHitCount } from './search-check-operate/display-hit-count'
import { refreshCount } from './search-check-operate/refresh-count'
import { syncCheckedStatus } from './search-check-operate/sync-checked-status'

export const checkOperate = () => {
  document.addEventListener('DOMContentLoaded', async () => {
    // 全件チェック用のチェックボックスが押された場合の制御
    allCheck()

    // 全件チェック操作の対象になっている側のチェックボックスが操作された場合の、全件チェック用チェックボックスの操作
    allCheckTarget()

    // 選択中の表示判定
    displaySelecting()

    // 検索条件をクリア
    clearCondition()

    // 介護の求人を探すページにおいてチェックを入れた場合に検索ヒット数の要素を表示させるための設定
    displayHitCount()

    // 1つの <form> の中に PC と SP のチェックボックスが同居しているため
    // チェック状態が正しく送信されないため
    // PC と SP のチェックボックスを同期する
    syncCheckedStatus()

    // ヒット件数の取得と件数の更新
    await refreshCount()
  })
}
