export const fixedHeader = () => {
  window.addEventListener('scroll', () => {
    const fixedElem = document.querySelector('.js__header-fixed')
    const header01 = document.querySelector('.m__header__section01')
    const header02 = document.querySelector('.m__header__section02')
    if (fixedElem != null) {
      const scrollCount = document.documentElement.scrollTop,
        headerHeight = header01.clientHeight + header02.clientHeight
      if (scrollCount > headerHeight) {
        fixedElem.classList.add('fixed')
      } else {
        fixedElem.classList.remove('fixed')
      }
    }
  })
}
