export const spAccordion = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const slideUp = (el, duration = 300) => {
      el.style.height = el.offsetHeight + 'px'
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      setTimeout(() => {
        el.style.display = 'none'
        el.style.removeProperty('height')
        el.style.removeProperty('padding-top')
        el.style.removeProperty('padding-bottom')
        el.style.removeProperty('margin-top')
        el.style.removeProperty('margin-bottom')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    }

    // slideDown
    const slideDown = (el, duration = 300) => {
      el.style.removeProperty('display')
      let display = window.getComputedStyle(el).display
      if (display === 'none') {
        display = 'block'
      }
      el.style.display = display
      let height = el.offsetHeight
      el.style.overflow = 'hidden'
      el.style.height = 0
      el.style.paddingTop = 0
      el.style.paddingBottom = 0
      el.style.marginTop = 0
      el.style.marginBottom = 0
      el.offsetHeight
      el.style.transitionProperty = 'height, margin, padding'
      el.style.transitionDuration = duration + 'ms'
      el.style.transitionTimingFunction = 'ease'
      el.style.height = height + 'px'
      el.style.removeProperty('padding-top')
      el.style.removeProperty('padding-bottom')
      el.style.removeProperty('margin-top')
      el.style.removeProperty('margin-bottom')
      setTimeout(() => {
        el.style.removeProperty('height')
        el.style.removeProperty('overflow')
        el.style.removeProperty('transition-duration')
        el.style.removeProperty('transition-property')
        el.style.removeProperty('transition-timing-function')
      }, duration)
    }

    const slideToggle = (el, duration = 300) => {
      if (window.getComputedStyle(el).display === 'none') {
        return slideDown(el, duration)
      } else {
        return slideUp(el, duration)
      }
    }

    const spTrigger = document.querySelectorAll('.js__sp-accordion-trigger')
    function toggle() {
      if (window.innerWidth < 737) {
        const content = this.nextElementSibling
        this.classList.toggle('open')
        content.classList.toggle('open')
        slideToggle(content, 300)
      }
    }
    for (let i = 0; i < spTrigger.length; i++) {
      spTrigger[i].addEventListener('click', toggle)
    }
  })
}
