// 全件チェック用のチェックボックスが押された場合の制御
export const allCheck = () => {
  let disabledCheckboxes
  if (window.innerWidth < 737) {
    disabledCheckboxes = document.querySelectorAll('.m__search__research-block .pc input')
  } else {
    disabledCheckboxes = document.querySelectorAll('.m__search__research-block .sp input')
  }
  disabledCheckboxes.forEach((disabledCheckbox) => {
    disabledCheckbox.disabled = true
  })

  let checkboxAllChecks
  const citySection = document.getElementById('js-city-section')
  const cityIdElements = document.querySelectorAll('[data-city-prefecture-id]')
  // 検索条件を変更するページかどうか
  if (citySection) {
    if (window.innerWidth > 736) {
      checkboxAllChecks = document.querySelectorAll('.pc .js__all-check')
    } else {
      checkboxAllChecks = document.querySelectorAll('.sp .js__all-check')
    }
  } else {
    checkboxAllChecks = document.querySelectorAll('.js__all-check')
  }

  checkboxAllChecks.forEach((checkboxAllCheck) => {
    // 「東京都」「神奈川県」など全件チェック機能を持つ各都道府県のチェック項目を取得
    const checkboxAllCheckInput = checkboxAllCheck.querySelector('input')
    const targetElem = checkboxAllCheck.nextElementSibling.childNodes
    const url = location.href.split("/").filter(e => Boolean(e))
    const urlLast = url[url.length - 1]
    if (citySection) {
      let areaValue
      const areaCheckBoxes = document.querySelectorAll('.checkbox-area-trigger input')
      areaCheckBoxes.forEach((areaCheckBox) => {
        areaValue = areaCheckBox.value

        if (window.innerWidth > 736) {
          if (areaCheckBox.checked == true) {
            cityIdElements.forEach((cityIdElement) => {
              if (cityIdElement.getAttribute('data-city-prefecture-id') == areaValue) {
                cityIdElement.style.display = 'block'
                // CATの都道府県ページのみ、全件チェックを実行する(PC)
                if (urlLast.match(/area_/)) {
                  cityIdElement.querySelectorAll('input').forEach((checkElem) => {
                    checkElem.checked = true
                  })
                }
              }
            })
          }
          areaCheckBox.addEventListener('change', () => {
            areaValue = areaCheckBox.value
            cityIdElements.forEach((cityIdElement) => {
              if (cityIdElement.getAttribute('data-city-prefecture-id') == areaValue) {
                cityIdElement.querySelectorAll('input').forEach((resetCheck) => {
                  if (areaCheckBox.checked == false) {
                    resetCheck.checked = false
                  }
                })
              }
            })
          })
        }
      })
    }
    // 全件チェック機能を持つ項目のチェック状況によって、対象の項目のチェック状況を操作する
    checkboxAllCheckInput.addEventListener('change', () => {
      targetElem.forEach((checkElem) => {
        const checkbox = checkElem.querySelector('input')
        if (checkboxAllCheckInput.checked) {
          checkbox.checked = true
        } else {
          checkbox.checked = false
        }
      })
    })
    // CATの都道府県ページのみ、全件チェックを実行する(SP)
    if (urlLast.match(/area_/) && window.innerWidth < 737) {
      if (checkboxAllCheckInput.checked == true) {
        const loadAllChecks = checkboxAllCheck.parentElement.querySelectorAll('input')
        loadAllChecks.forEach((loadAllCheck) => {
          loadAllCheck.checked = true
        })
      }
    }
  })
}
