export const searchMoreBtn = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const trigger = document.querySelectorAll('.js__accordion-more-btn')
    function toggle() {
      const content = this.previousElementSibling
      this.classList.toggle('open')
      content.classList.toggle('all-open')
    }
    for (let i = 0; i < trigger.length; i++) {
      trigger[i].addEventListener('click', toggle)
    }
  })
}
