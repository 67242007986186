export var bulkEntry = function () {
    document.addEventListener('DOMContentLoaded', function () {
        Array.from(document.querySelectorAll('[data-bulk-entry-button'))
            .filter(function (el) { return el instanceof HTMLButtonElement; })
            .forEach(function (el) {
            var generalEtnryUrl = el.dataset.generalEntryUrl;
            var notGeneralEntryUrl = el.dataset.notGeneralEntryUrl;
            el.addEventListener('click', function (e) {
                var checkedEls = Array.from(document.querySelectorAll('input[data-entry-checkbox="1"]')).filter(function (checkBoxEl) { return checkBoxEl.checked; });
                var generalChecked = checkedEls.findIndex(function (checkedEl) {
                    return JSON.parse(checkedEl.dataset.isGeneral);
                }) >= 0;
                var notGeneralChecked = checkedEls.findIndex(function (checkedEl) {
                    return !JSON.parse(checkedEl.dataset.isGeneral);
                }) >= 0;
                if (generalChecked && notGeneralChecked) {
                    var aEl = document.getElementById('js-move-bulk-entry');
                    if (aEl instanceof HTMLElement) {
                        aEl.setAttribute('href', "".concat(notGeneralEntryUrl, "&").concat(buildlUrlQuery(false)));
                    }
                    return;
                }
                // modal 表示されないようにイベント伝達をストップする
                e.preventDefault();
                e.stopPropagation();
                if (generalChecked) {
                    window.open("".concat(generalEtnryUrl, "&").concat(buildlUrlQuery(true)), '_blank');
                    return;
                }
                if (notGeneralChecked) {
                    window.open("".concat(notGeneralEntryUrl, "&").concat(buildlUrlQuery(false)), '_blank');
                    return;
                }
            });
        });
    });
};
var buildlUrlQuery = function (isGeneral) {
    var checkBoxEls = Array.from(document.querySelectorAll("input[data-entry-checkbox=\"1\"][data-is-general=\"".concat(JSON.stringify(isGeneral), "\"]")));
    return checkBoxEls
        .filter(function (el) { return el.checked; })
        .map(function (el) { return "job_id=".concat(el.dataset.jobId); })
        .join('&');
};
