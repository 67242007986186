import { smoothScroll } from './smooth-scroll';
import { scrollFixed } from './scroll-fixed';
import { mvScroll } from './mv-scroll';
import { carousel } from './carousel';
import { accordion } from './accordion';
import { spAccordion } from './sp-accordion';
import { searchMoreBtn } from './search-more-btn';
import { textMore } from './text-more';
import { readmore } from './read-more-btn';
import { tabList } from './tab-list';
import { spHeaderMenu } from './sp-header-menu';
import { pcHeaderMenu } from './pc-header-menu';
import { fixedHeader } from './fixed-header';
import { checkboxFloatBanner } from './checkbox-float-banner';
import { modal } from './modal';
import { checkOperate } from './search-check-operate';
import { refreshCount } from './home/refresh-count';
import { bulkEntry } from './bulk-entry';
import { bracketsCenterText } from './brackets-text-center';
import '../../stylesheets/site/index.styl';
smoothScroll();
scrollFixed();
mvScroll();
carousel();
checkOperate();
accordion();
spAccordion();
searchMoreBtn();
textMore();
readmore();
tabList();
spHeaderMenu('.js__sp-menu-trigger');
pcHeaderMenu();
fixedHeader();
checkboxFloatBanner();
modal();
refreshCount();
bulkEntry();
bracketsCenterText();
