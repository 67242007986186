export const pcHeaderMenu = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const subMenu = document.querySelectorAll('.m__header .pc-list > li')
    const headerWrap = document.querySelector('.m__header__section02')
    let hasClass = false
    subMenu.forEach((subMenuTarget) => {
      const subMenuContent = subMenuTarget.lastElementChild
      subMenuTarget.addEventListener('mouseover', () => {
        if (subMenuTarget.classList.contains('sub-menu-link') == true) {
          if (hasClass == true) {
            subMenu.forEach((subMenuTarget) => {
              subMenuTarget.classList.add('fade')
              setTimeout(() => {
                subMenuTarget.classList.remove('fade')
              }, 50)
            })
          }
          let menuHeight = 0
          if (subMenuContent.classList.contains('sub-menu')) {
            menuHeight = subMenuContent.clientHeight
          }
          headerWrap.style.paddingBottom = menuHeight + 'px'
          subMenuContent.classList.add('open')
          if (subMenuTarget.classList.contains('sub-menu-link') == true) {
            hasClass = true
          }
        } else {
          hasClass = false
        }
      })
      subMenuTarget.addEventListener('mouseleave', () => {
        subMenuContent.classList.remove('open')
        headerWrap.style.paddingBottom = 0
      })
      headerWrap.addEventListener('mouseleave', () => {
        subMenu.forEach(() => {
          hasClass = false
        })
      })
    })
  })
}
