export const textMore = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const trigger = document.querySelectorAll('.js__sp-text-more-btn')
    function toggle() {
      if (window.innerWidth < 737) {
        const content = this.parentElement
        content.classList.toggle('all-open')
      }
    }
    for (let i = 0; i < trigger.length; i++) {
      trigger[i].addEventListener('click', toggle)
    }
    const accordionTexts = document.querySelectorAll('.text-accordion p')
    if (trigger.length > 0) {
      accordionTexts.forEach((accordionText) => {
        const textOuterHeight = accordionText.scrollHeight
        const textInnerHeight = accordionText.clientHeight
        if (textInnerHeight < textOuterHeight) {
          accordionText.nextElementSibling.style.display = 'block'
        }
      })
    }
  })
}
