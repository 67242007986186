export const modal = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const modalTriggers = document.querySelectorAll('.js__modal-trigger')
    const contents = document.querySelectorAll('.js__modal-content')
    const modalInners = document.querySelectorAll('.m__modal__inner')
    const searchContents = document.querySelector(
      '.m__search__research-contents-block'
    )
    const modalSubmitArea = document.querySelector(
      '.m__top__sp-modal .modal-submit-area'
    )
    let modalCountBlockHeight = 0
    const modalCloseBtns = document.querySelectorAll('.modal-close-btn')
    modalCloseBtns.forEach((modalCloseBtn) => {
      modalCloseBtn.addEventListener('click', () => {
        contents.forEach((content) => {
          content.style.display = 'none'
        })
      })
    })
    contents.forEach((modalCloseContent) => {
      modalCloseContent.addEventListener('click', () => {
        modalCloseContent.style.display = 'none'
      })
    })
    modalInners.forEach((modalInner) => {
      modalInner.addEventListener('click', (event) => {
        event.stopPropagation()
      })
    })
    modalTriggers.forEach((modalTrigger) => {
      const modalTriggerData = modalTrigger.dataset.modal
      const targetModal = document.getElementById(modalTriggerData)
      let windowHeight = window.innerHeight
      modalTrigger.addEventListener('click', () => {
        targetModal.style.display = 'block'
        if (searchContents != null) {
          if (window.innerWidth < 768) {
            modalCountBlockHeight = document.querySelector(
              '.m__modal__wrap .m__search__count-block'
            ).clientHeight
            searchContents.style.paddingBottom = modalCountBlockHeight + 'px'
          }
        }
        if (modalSubmitArea != null) {
          if (window.innerWidth < 768) {
            modalCountBlockHeight = modalSubmitArea.clientHeight
            targetModal.childNodes[0].style.height = windowHeight + 'px'
          }
        }
      })
      if (window.innerWidth < 768) {
        window.addEventListener('scroll', () => {
          windowHeight = window.innerHeight
          targetModal.childNodes[0].style.height = windowHeight + 'px'
          if (searchContents != null) {
            searchContents.parentElement.style.height = windowHeight + 'px'
          }
        })
      }
    })
  })
}
